import { withIcon } from "@icons/_withIcon";

export const Phone = withIcon(
  ({ primary }) => (
    <path
      d="M14.4458 7.63331C14.9704 5.83825 14.5249 3.81981 13.1093 2.40414C11.6936 0.988476 9.67515 0.542959 7.88009 1.06759M12.0903 7.3274C12.3879 6.30936 12.1352 5.16464 11.3323 4.36176C10.5295 3.55889 9.38474 3.30622 8.3667 3.60376M9.00093 14.3846C5.72372 12.4942 2.99606 9.78201 1.08711 6.51559L1.02796 6.41437C0.535192 5.57119 0.673114 4.50175 1.36368 3.81119L3.13033 2.04453C3.52086 1.65401 4.15401 1.65401 4.54454 2.04452L6.13752 3.63746C6.58374 4.08367 6.51019 4.82658 5.98512 5.17662L5.01499 5.82338C4.48993 6.17341 4.41637 6.9163 4.86257 7.36252L8.13754 10.6375C8.58376 11.0837 9.32665 11.0101 9.67669 10.4851L10.3234 9.51494C10.6735 8.98988 11.4164 8.91632 11.8626 9.36255L13.3387 10.8387C13.7292 11.2292 13.7292 11.8623 13.3387 12.2529L11.542 14.0496C10.8683 14.7233 9.82619 14.8607 9.00093 14.3846Z"
      stroke={primary}
      strokeLinecap="round"
      fill="none"
    />
  ),
  { viewBox: "0 0 16 16" }
);
