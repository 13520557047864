import { withIcon } from "@icons/_withIcon";

export const Book = withIcon(
  ({ primary }) => (
    <>
      <path
        d="M15.1629 2.68002C12.8692 1.75464 10.3102 1.73142 7.99995 2.61502C5.68973 1.73142 3.13075 1.75464 0.836949 2.68002C0.744262 2.71717 0.66482 2.78121 0.608856 2.86391C0.552893 2.94661 0.522973 3.04417 0.522949 3.14402V14.531C0.522903 14.6129 0.542952 14.6935 0.581337 14.7658C0.619721 14.8381 0.675268 14.8998 0.743099 14.9457C0.81093 14.9915 0.888971 15.0199 0.97037 15.0285C1.05177 15.0371 1.13404 15.0256 1.20995 14.995C3.32704 14.1409 5.69285 14.1409 7.80995 14.995C7.83964 15.0039 7.87011 15.0099 7.90095 15.013C7.92232 15.0185 7.94404 15.0225 7.96595 15.025C7.97695 15.025 7.98695 15.031 7.99695 15.031C8.00695 15.031 8.03695 15.015 8.05795 15.013C8.10055 15.0125 8.14291 15.0065 8.18395 14.995C10.301 14.1409 12.6669 14.1409 14.7839 14.995C14.8433 15.0192 14.9069 15.0315 14.9709 15.031C15.1036 15.031 15.2307 14.9783 15.3245 14.8846C15.4183 14.7908 15.4709 14.6636 15.4709 14.531V3.14402C15.4714 3.04486 15.4424 2.94779 15.3875 2.86517C15.3327 2.78255 15.2545 2.7181 15.1629 2.68002ZM1.52295 13.82V3.48802C3.45427 2.7907 5.56863 2.7907 7.49995 3.48802V13.82C5.55606 13.1987 3.46684 13.1987 1.52295 13.82ZM14.4769 13.82C12.5331 13.1987 10.4438 13.1987 8.49995 13.82V3.48802C10.4313 2.7907 12.5456 2.7907 14.4769 3.48802V13.82Z"
        fill={primary}
      />
      <path
        d="M12.3648 10.771C11.4627 10.6943 10.5542 10.7414 9.66484 10.911C9.54272 10.9345 9.43373 11.0027 9.35916 11.1022C9.28458 11.2017 9.24977 11.3255 9.26151 11.4493C9.27326 11.5731 9.33071 11.688 9.42267 11.7718C9.51463 11.8555 9.63448 11.9019 9.75884 11.902C9.79233 11.902 9.82577 11.8994 9.85884 11.894C10.6586 11.7413 11.4756 11.6989 12.2868 11.768C12.3523 11.7737 12.4182 11.7663 12.4809 11.7464C12.5435 11.7266 12.6016 11.6945 12.6519 11.6522C12.7021 11.6098 12.7435 11.558 12.7737 11.4996C12.8039 11.4413 12.8223 11.3775 12.8278 11.312C12.8334 11.246 12.8259 11.1796 12.8055 11.1165C12.7852 11.0534 12.7526 10.995 12.7095 10.9447C12.6664 10.8944 12.6138 10.8531 12.5546 10.8233C12.4954 10.7935 12.4309 10.7757 12.3648 10.771Z"
        fill={primary}
      />
      <path
        d="M13.3119 8.08109C12.105 7.85605 10.8664 7.86012 9.66093 8.09309C9.53881 8.11658 9.42983 8.18474 9.35525 8.28425C9.28068 8.38377 9.24586 8.50751 9.25761 8.63131C9.26935 8.75511 9.32681 8.87009 9.41876 8.95381C9.51072 9.03754 9.63057 9.08398 9.75493 9.08409C9.78847 9.08423 9.82195 9.08122 9.85493 9.07509C10.9376 8.86612 12.0498 8.86205 13.1339 9.06309C13.261 9.08006 13.3898 9.04755 13.4936 8.97226C13.5974 8.89698 13.6684 8.78469 13.6917 8.65861C13.7151 8.53252 13.6891 8.40227 13.6192 8.29479C13.5492 8.1873 13.4407 8.1108 13.3159 8.08109H13.3119Z"
        fill={primary}
      />
      <path
        d="M9.66106 5.27412C9.53774 5.29641 9.42729 5.36419 9.35158 5.46405C9.27587 5.56391 9.24041 5.68855 9.25223 5.81331C9.26405 5.93807 9.32229 6.05383 9.41541 6.13769C9.50853 6.22155 9.62975 6.26738 9.75506 6.26612C9.7886 6.26625 9.82208 6.26324 9.85506 6.25712C10.9377 6.04815 12.0499 6.04407 13.1341 6.24512C13.2641 6.26773 13.3978 6.23853 13.5066 6.16376C13.6154 6.089 13.6906 5.97462 13.7161 5.84512C13.74 5.71492 13.7114 5.58053 13.6364 5.47142C13.5614 5.36232 13.4462 5.28741 13.3161 5.26312C12.1078 5.03724 10.8679 5.04097 9.66106 5.27412Z"
        fill={primary}
      />
      <path
        d="M6.33487 10.8999C5.12813 10.6759 3.8901 10.68 2.68487 10.9119C2.56283 10.9354 2.45391 11.0035 2.37933 11.1029C2.30476 11.2023 2.26987 11.3259 2.28149 11.4496C2.2931 11.5734 2.35037 11.6883 2.44214 11.7721C2.53391 11.8559 2.65359 11.9026 2.77787 11.9029C2.81136 11.903 2.8448 11.9003 2.87787 11.8949C3.9605 11.6859 5.07273 11.6819 6.15687 11.8829C6.28574 11.9036 6.41757 11.873 6.52421 11.7978C6.63085 11.7225 6.70384 11.6086 6.7276 11.4802C6.75136 11.3519 6.724 11.2194 6.65137 11.1109C6.57873 11.0025 6.46658 10.9268 6.33887 10.8999H6.33487Z"
        fill={primary}
      />
      <path
        d="M6.33487 8.08109C5.12822 7.85605 3.89001 7.86012 2.68487 8.09309C2.56283 8.11656 2.45391 8.18463 2.37933 8.28404C2.30476 8.38346 2.26987 8.50708 2.28149 8.63081C2.2931 8.75454 2.35037 8.86951 2.44214 8.95331C2.53391 9.03711 2.65359 9.08373 2.77787 9.08409C2.81141 9.08432 2.8449 9.08131 2.87787 9.07509C3.96055 8.86661 5.07269 8.86254 6.15687 9.06309C6.28398 9.08006 6.41274 9.04754 6.51656 8.97226C6.62037 8.89698 6.69129 8.78469 6.71466 8.6586C6.73803 8.53251 6.71206 8.40227 6.64212 8.29478C6.57218 8.1873 6.46361 8.1108 6.33887 8.08109H6.33487Z"
        fill={primary}
      />
      <path
        d="M6.33493 5.2629C5.12831 5.03796 3.89017 5.04169 2.68493 5.2739C2.56171 5.29619 2.45134 5.3639 2.37564 5.46364C2.29994 5.56339 2.26442 5.68791 2.27611 5.81258C2.2878 5.93726 2.34584 6.05301 2.43876 6.13695C2.53168 6.22088 2.65272 6.2669 2.77793 6.2659C2.81148 6.26613 2.84497 6.26312 2.87793 6.2569C3.96062 6.04842 5.07275 6.04435 6.15693 6.2449C6.28713 6.26886 6.42152 6.2402 6.53062 6.16522C6.63973 6.09023 6.71464 5.97504 6.73893 5.8449C6.75075 5.78014 6.74967 5.71369 6.73575 5.64936C6.72182 5.58502 6.69533 5.52407 6.6578 5.47C6.62026 5.41592 6.57242 5.36979 6.51701 5.33426C6.46161 5.29872 6.39973 5.27447 6.33493 5.2629Z"
        fill={primary}
      />
    </>
  ),
  { viewBox: "0 0 16 17" }
);
