import { withIcon } from "@icons/_withIcon";

export const User = withIcon(
  ({ primary }) => (
    <>
      <path
        d="M8 16C9.48336 16 10.9334 15.5601 12.1668 14.736C13.4001 13.9119 14.3614 12.7406 14.9291 11.3701C15.4968 9.99968 15.6453 8.49168 15.3559 7.03683C15.0665 5.58197 14.3522 4.2456 13.3033 3.1967C12.2544 2.14781 10.918 1.4335 9.46318 1.14411C8.00832 0.854725 6.50032 1.00325 5.12987 1.57091C3.75943 2.13856 2.58809 3.09986 1.76398 4.33323C0.939867 5.5666 0.5 7.01664 0.5 8.5C0.5 10.4891 1.29018 12.3968 2.6967 13.8033C4.10322 15.2098 6.01088 16 8 16ZM3.5 13.18V12.8C3.50039 12.4528 3.62122 12.1165 3.84188 11.8484C4.06253 11.5804 4.36935 11.3971 4.71 11.33C6.88128 10.8901 9.11872 10.8901 11.29 11.33C11.6307 11.3971 11.9375 11.5804 12.1581 11.8484C12.3788 12.1165 12.4996 12.4528 12.5 12.8V13.18C11.2932 14.3481 9.67953 15.0013 8 15.0013C6.32047 15.0013 4.70676 14.3481 3.5 13.18ZM8 2C9.17809 1.99976 10.3341 2.3197 11.3444 2.92561C12.3547 3.53153 13.1814 4.40063 13.736 5.44C14.2906 6.47938 14.5523 7.64994 14.4931 8.82654C14.4339 10.0031 14.0561 11.1415 13.4 12.12C13.274 11.6785 13.0293 11.28 12.6925 10.9679C12.3557 10.6559 11.9398 10.4421 11.49 10.35C9.18062 9.8772 6.79938 9.8772 4.49 10.35C4.04385 10.4454 3.63214 10.6606 3.29913 10.9725C2.96613 11.2843 2.72442 11.6811 2.6 12.12C1.9439 11.1415 1.56606 10.0031 1.50687 8.82654C1.44769 7.64994 1.70939 6.47938 2.264 5.44C2.81861 4.40063 3.64526 3.53153 4.65559 2.92561C5.66591 2.3197 6.82191 1.99976 8 2Z"
        fill={primary}
      />
      <path
        d="M8 9C8.59334 9 9.17336 8.82405 9.66671 8.49441C10.1601 8.16477 10.5446 7.69623 10.7716 7.14805C10.9987 6.59987 11.0581 5.99667 10.9424 5.41473C10.8266 4.83279 10.5409 4.29824 10.1213 3.87868C9.70176 3.45912 9.16721 3.1734 8.58527 3.05765C8.00333 2.94189 7.40013 3.0013 6.85195 3.22836C6.30377 3.45543 5.83524 3.83994 5.50559 4.33329C5.17595 4.82664 5 5.40666 5 6C5 6.79565 5.31607 7.55871 5.87868 8.12132C6.44129 8.68393 7.20435 9 8 9ZM8 4C8.39556 4 8.78224 4.1173 9.11114 4.33706C9.44004 4.55682 9.69638 4.86918 9.84776 5.23463C9.99913 5.60009 10.0387 6.00222 9.96157 6.39018C9.8844 6.77814 9.69392 7.13451 9.41421 7.41422C9.13451 7.69392 8.77814 7.8844 8.39018 7.96157C8.00222 8.03874 7.60009 7.99914 7.23463 7.84776C6.86918 7.69639 6.55682 7.44004 6.33706 7.11114C6.1173 6.78224 6 6.39556 6 6C6 5.46957 6.21071 4.96086 6.58579 4.58579C6.96086 4.21072 7.46957 4 8 4Z"
        fill={primary}
      />
    </>
  ),
  { viewBox: "0 0 16 17" }
);
