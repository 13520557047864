import PropTypes from "prop-types";
import cn from "classnames";

import { keyify } from "@utils/keyify";
import { ImgixNextImage } from "@components/imgixNextImage";
import { Eyebrow } from "@components/eyebrow";
import { dangerHTML } from "@utils/danger";

export function BasicCard({
  image = {},
  onClick = () => {},
  eyebrow = "",
  fullBackgroundImage = false,
  taxonomy = null,
  type = "general",
  href,
  title,
}) {
  return (
    <article
      key={keyify(title)}
      className={cn(
        fullBackgroundImage
          ? "relative rounded overflow-hidden flex-none w-full aspect-square bg-black-100"
          : "relative flex-shrink-0 lg:flex-shrink mx-4 first:ml-0 last:mr-0 w-full aspect-square"
      )}
    >
      {taxonomy && (
        <div
          className="absolute px-2 font-bold border border-black rounded top-5 left-5 md:top-2 md:left-2 lg:top-5 lg:left-5 bg-sand-100 lg:px-4"
          {...dangerHTML(taxonomy)}
        />
      )}
      {image && (
        <ImgixNextImage
          src={image?.url}
          alt={image?.alt || title}
          type={type}
          className={cn(
            fullBackgroundImage
              ? "z-10 h-full"
              : "rounded w-full h-full max-h-96"
          )}
          width={360}
          height={360}
          imgixParams={{
            fit: "crop",
            w: "360",
            ar: "1:1",
          }}
        />
      )}
      <div
        className={cn(
          fullBackgroundImage
            ? "absolute top-0 left-0 z-20 w-full h-full flex flex-col justify-end items-center bg-gradient-to-t from-transparent-60 via-transparent to-transparent py-8 text-white"
            : ""
        )}
      >
        {fullBackgroundImage ? (
          <a
            href={href}
            className="w-5/6 mx-auto text-center card-link"
            onClick={onClick}
          >
            <h3 className="text-2xl font-semibold">{title}</h3>
            <p className="overflow-hidden text-sm whitespace-nowrap text-ellipsis">
              {eyebrow}
            </p>
          </a>
        ) : (
          <a
            href={href}
            className="text-lg font-bold card-link lg:text-xl"
            onClick={onClick}
          >
            <Eyebrow className="z-10 mt-4">{eyebrow}</Eyebrow>
            {title}
          </a>
        )}
      </div>
    </article>
  );
}

BasicCard.propTypes = {
  href: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  taxonomy: PropTypes.string,
  eyebrow: PropTypes.string,
  onClick: PropTypes.func,
  fullBackgroundImage: PropTypes.bool,
  type: PropTypes.string,
};
