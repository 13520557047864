/**
 * Return an object that can be spread onto a React component
 *
 * @example
 * <script {...dangerHTML("alert('Hello, World!');"} />
 *
 * @param  string __html
 * @return object
 */
export function dangerHTML(__html) {
  return {
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML: {
      __html,
    },
  };
}
