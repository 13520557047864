import PropTypes from "prop-types";
import cn from "classnames";

export function ListItem({ icon, title, extraIcon = null, className = "" }) {
  return (
    <li className={cn("flex items-center w-full", className)}>
      <span className="mr-3">{icon}</span>
      <span>{title}</span>
      {extraIcon && <sup className="ml-1">{extraIcon}</sup>}
    </li>
  );
}

ListItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  extraIcon: PropTypes.node,
  className: PropTypes.string,
};
