import PropTypes from "prop-types";
import cn from "classnames";

export function SectionHeading({
  className = "",
  elem: Elem = "h2",
  minor = false,
  fontNormal = false,
  children,
}) {
  return (
    <Elem
      className={cn(
        !minor ? "text-4xl md:text-5xl" : "text-lg md:text-2xl",
        "font-display leading-tight",
        { "font-semibold": !fontNormal },
        { "font-light": fontNormal },
        className
      )}
      data-testid="page-heading"
    >
      {children}
    </Elem>
  );
}

SectionHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    /**
     * String interpolation creates an array, such that `See ${location}`
     * arrives as ["See ", "Germany"]
     */
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
  elem: PropTypes.oneOf(["h1", "h2", "h3"]),
  minor: PropTypes.bool,
  fontNormal: PropTypes.bool,
};
