import { withIcon } from "@icons/_withIcon";

export const Car = withIcon(
  ({ primary }) => (
    <>
      <circle cx="4" cy="13.5" r="1.5" stroke={primary} />
      <circle cx="12" cy="13.5" r="1.5" stroke={primary} />
      <path
        d="M14 13.5H15V10.5L12 9.5L11.2279 7.18377C11.0918 6.77543 10.7097 6.5 10.2792 6.5H3C1.89543 6.5 1 7.39543 1 8.5V13.5H2"
        stroke={primary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.5H1"
        stroke={primary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.5L6 13.5"
        stroke={primary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 4V4.5H3V4C3 3.17157 3.67157 2.5 4.5 2.5H8.5C9.32843 2.5 10 3.17157 10 4Z"
        stroke={primary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6.5V9.5"
        stroke={primary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  { viewBox: "0 0 16 17", fill: "none" }
);
