import PropTypes from "prop-types";
import cn from "classnames";

export function Pageblock({
  className = "",
  flush = false,
  inset = false,
  children,
  ...rest
}) {
  if (!children) return null;

  return (
    <section
      className={cn(
        "relative",
        { "my-12 md:mt-20": !flush && !inset },
        { "py-12 md:py-20": inset },
        className
      )}
      {...rest}
    >
      {children}
    </section>
  );
}

Pageblock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  flush: PropTypes.bool,
  inset: PropTypes.bool,
};
