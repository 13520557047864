import { withIcon } from "@icons/_withIcon";

export const Info = withIcon(
  ({ primary }) => (
    <>
      <path
        d="M6 5.795C5.90055 5.795 5.80516 5.83451 5.73484 5.90484C5.66451 5.97516 5.625 6.07054 5.625 6.17V9.5C5.625 9.59946 5.66451 9.69484 5.73484 9.76517C5.80516 9.83549 5.90055 9.875 6 9.875C6.09946 9.875 6.19484 9.83549 6.26517 9.76517C6.33549 9.69484 6.375 9.59946 6.375 9.5V6.17C6.375 6.07054 6.33549 5.97516 6.26517 5.90484C6.19484 5.83451 6.09946 5.795 6 5.795Z"
        fill={primary}
      />
      <path
        d="M6 4.67C6.41422 4.67 6.75 4.33421 6.75 3.92C6.75 3.50579 6.41422 3.17 6 3.17C5.58579 3.17 5.25 3.50579 5.25 3.92C5.25 4.33421 5.58579 4.67 6 4.67Z"
        fill={primary}
      />
      <path
        d="M6 0.875C4.88748 0.875 3.79995 1.2049 2.87492 1.82298C1.94989 2.44107 1.22892 3.31957 0.80318 4.34741C0.377437 5.37524 0.266043 6.50624 0.483085 7.59738C0.700127 8.68853 1.23586 9.6908 2.02253 10.4775C2.8092 11.2641 3.81148 11.7999 4.90262 12.0169C5.99376 12.234 7.12476 12.1226 8.1526 11.6968C9.18043 11.2711 10.0589 10.5501 10.677 9.62508C11.2951 8.70005 11.625 7.61252 11.625 6.5C11.625 5.00816 11.0324 3.57742 9.97748 2.52252C8.92259 1.46763 7.49185 0.875 6 0.875ZM6 11.375C5.03582 11.375 4.09329 11.0891 3.2916 10.5534C2.48991 10.0177 1.86507 9.25637 1.49609 8.36558C1.12711 7.47479 1.03057 6.49459 1.21867 5.54893C1.40678 4.60328 1.87108 3.73464 2.55286 3.05285C3.23464 2.37107 4.10328 1.90677 5.04894 1.71867C5.99459 1.53057 6.97479 1.62711 7.86558 1.99609C8.75637 2.36506 9.51775 2.98991 10.0534 3.79159C10.5891 4.59328 10.875 5.53582 10.875 6.5C10.873 7.79232 10.3588 9.03114 9.44496 9.94495C8.53115 10.8588 7.29232 11.373 6 11.375Z"
        fill={primary}
      />
    </>
  ),
  { viewBox: "0 0 12 13" }
);
