import { withIcon } from "@icons/_withIcon";

export const Star = withIcon(
  ({ primary }) => (
    <path
      d="M11.7692 3.47404L12.8462 2.38077M4.23077 3.47404L3.15385 2.38077M8 14.4067V15.5M13.9231 10.307L15 10.8536M2.07692 10.307L1 10.8536M10.0338 5.36483L9.02279 2.25102C8.69766 1.24966 7.30234 1.24966 6.97721 2.25102L5.96618 5.36483H2.73786C1.69088 5.36483 1.25961 6.72807 2.11081 7.34693L4.70922 9.23612L3.70847 12.3183C3.38232 13.3228 4.5113 14.1652 5.35831 13.5494L8 11.6287L10.6417 13.5494C11.4887 14.1652 12.6177 13.3228 12.2915 12.3183L11.2908 9.23612L13.8892 7.34693C14.7404 6.72807 14.3091 5.36483 13.2621 5.36483H10.0338Z"
      stroke={primary}
      strokeLinecap="round"
      fill="none"
    />
  ),
  { viewBox: "0 0 16 17" }
);
