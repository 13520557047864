import PropTypes from "prop-types";
import cn from "classnames";

export function PageHeading({ className = "", children }) {
  return (
    <h1
      className={cn(
        "text-3xl font-display md:text-6xl leading-tighter font-semibold",
        className
      )}
    >
      {children}
    </h1>
  );
}

PageHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.string, PropTypes.shape({})]),
  ]).isRequired,
  className: PropTypes.string,
};
