import React, { useState, useMemo } from "react";
import { tagular } from "@cohesion/tagular";
import cn from "classnames";

import { ChevronRight } from "@icons";
import {
  Accommodation,
  Book,
  Car,
  Phone,
  Star,
  Earth,
  User,
  Info,
} from "@icons/elsewhere";
import { useScreens } from "@utils/component";

import { ListItem } from "./listItem";

export function Tabs() {
  const [activeTab, setActiveTab] = useState(0);
  const [showTabsBody, setShowTabsBody] = useState(false);

  const { screen } = useScreens();
  const isSmallScreen = ["sm", "md"].includes(screen);

  const listLayout = useMemo(
    () => (
      <div className="flex flex-col gap-y-2 xl:flex-row xl:gap-x-8 2xl:gap-x-12">
        <ul className="flex flex-col gap-2">
          <ListItem icon={<Accommodation />} title="Accommodation" />
          <ListItem icon={<Star />} title="Exclusive experiences" />
          <ListItem icon={<Earth />} title="Personalized trip crafting" />
          <ListItem icon={<User />} title="On-trip concierge services" />
        </ul>
        <ul className="flex flex-col gap-2">
          <ListItem
            icon={<Car />}
            title="On-the-ground transportation"
            extraIcon={<Info className="inline-block text-blue text-xs" />}
          />
          <ListItem
            icon={<Book />}
            title="Roadbook with local tips and recommendations"
          />
          <ListItem icon={<Phone />} title="24/7 support" />
          <ListItem
            icon={<Info className="text-blue -mr-1" />}
            title="International flights are not included"
            className="text-xs 2xl:pt-1"
          />
        </ul>
      </div>
    ),
    []
  );

  const tabsData = [
    {
      label: "How does it work?",
      body: "Make a trip request, connect with a local expert, and sit back while our experts craft a custom itinerary based on expertise, exclusivity, and ease. It’s a trip you couldn’t plan yourself, all with 24/7 on-the-ground support.",
    },
    {
      label: "Who are our local experts?",
      body: "Who knows better than a local? Elsewhere teams up with a global network of award-winning travel guides. Each and every trip crafted with Elsewhere is unique to you and your travel style.",
    },
    { label: "What’s included?", body: listLayout },
  ];

  return (
    <div>
      {!isSmallScreen && (
        <>
          <div className="flex items-center border-b border-[#D5C7FB]">
            {tabsData.map((tab, index) => (
              <button
                type="button"
                key={tab.label}
                onClick={() => {
                  setActiveTab(index);
                  tagular("click", {
                    actionOutcome: "EXPAND",
                    webElement: {
                      location: "SECTION",
                      elementType: "TAB",
                      position: `${index + 1}`,
                      text: tab.label,
                      name: "ELSEWHEREDRIVER",
                    },
                  });
                }}
                className={cn("lg:px-4 lg:py-3 font-semibold cursor-pointer", {
                  "bg-[#E9E4F6] rounded-t-md": activeTab === index,
                  "text-black-300": activeTab !== index,
                })}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="pt-6 pb-12">{tabsData[activeTab].body}</div>
        </>
      )}
      {isSmallScreen && (
        <div className="mt-5 mb-3">
          <button
            className="pb-3 font-semibold w-full flex items-center border-b border-[#D5C7FB] justify-between"
            type="button"
            onClick={() => {
              setShowTabsBody(!showTabsBody);
              tagular("click", {
                actionOutcome: showTabsBody ? "COLLAPSE" : "EXPAND",
                webElement: {
                  location: "SECTION",
                  elementType: "TAB",
                  position: "1",
                  text: tabsData[0].label,
                  name: "ELSEWHEREDRIVER",
                },
              });
            }}
          >
            {tabsData[0].label}
            <ChevronRight
              className={cn(
                "w-4 h-4 text-blue",
                showTabsBody ? " -rotate-90" : "rotate-90"
              )}
            />
          </button>
          {showTabsBody && <p className="mt-3">{tabsData[0].body}</p>}
        </div>
      )}
    </div>
  );
}
