import { withIcon } from "@icons/_withIcon";

export const Accommodation = withIcon(
  ({ primary }) => (
    <>
      <path
        d="M3.8999 9.51204C3.96556 9.51204 4.03058 9.4991 4.09124 9.47398C4.15191 9.44885 4.20703 9.41202 4.25346 9.36559C4.29988 9.31916 4.33671 9.26404 4.36184 9.20338C4.38697 9.14272 4.3999 9.0777 4.3999 9.01204V7.98804C4.3999 7.85543 4.34722 7.72825 4.25346 7.63448C4.15969 7.54072 4.03251 7.48804 3.8999 7.48804C3.76729 7.48804 3.64012 7.54072 3.54635 7.63448C3.45258 7.72825 3.3999 7.85543 3.3999 7.98804V9.01204C3.3999 9.14465 3.45258 9.27182 3.54635 9.36559C3.64012 9.45936 3.76729 9.51204 3.8999 9.51204Z"
        fill={primary}
      />
      <path
        d="M6.68994 9.51204C6.82255 9.51204 6.94973 9.45936 7.04349 9.36559C7.13726 9.27182 7.18994 9.14465 7.18994 9.01204V7.98804C7.18994 7.85543 7.13726 7.72825 7.04349 7.63448C6.94973 7.54072 6.82255 7.48804 6.68994 7.48804C6.55733 7.48804 6.43016 7.54072 6.33639 7.63448C6.24262 7.72825 6.18994 7.85543 6.18994 7.98804V9.01204C6.18994 9.14465 6.24262 9.27182 6.33639 9.36559C6.43016 9.45936 6.55733 9.51204 6.68994 9.51204Z"
        fill={primary}
      />
      <path
        d="M3.8999 6.30694C3.96556 6.30694 4.03058 6.294 4.09124 6.26888C4.15191 6.24375 4.20703 6.20692 4.25346 6.16049C4.29988 6.11406 4.33671 6.05894 4.36184 5.99828C4.38697 5.93761 4.3999 5.8726 4.3999 5.80694V4.78394C4.3999 4.65133 4.34722 4.52415 4.25346 4.43038C4.15969 4.33661 4.03251 4.28394 3.8999 4.28394C3.76729 4.28394 3.64012 4.33661 3.54635 4.43038C3.45258 4.52415 3.3999 4.65133 3.3999 4.78394V5.80694C3.3999 5.93954 3.45258 6.06672 3.54635 6.16049C3.64012 6.25426 3.76729 6.30694 3.8999 6.30694Z"
        fill={primary}
      />
      <path
        d="M6.68994 6.30694C6.82255 6.30694 6.94973 6.25426 7.04349 6.16049C7.13726 6.06672 7.18994 5.93954 7.18994 5.80694V4.78394C7.18994 4.65133 7.13726 4.52415 7.04349 4.43038C6.94973 4.33661 6.82255 4.28394 6.68994 4.28394C6.55733 4.28394 6.43016 4.33661 6.33639 4.43038C6.24262 4.52415 6.18994 4.65133 6.18994 4.78394V5.80694C6.18994 5.93954 6.24262 6.06672 6.33639 6.16049C6.43016 6.25426 6.55733 6.30694 6.68994 6.30694Z"
        fill={primary}
      />
      <path
        d="M12.0052 11.3099C12.495 11.3099 12.8922 10.9128 12.8922 10.4229C12.8922 9.93301 12.495 9.53589 12.0052 9.53589C11.5153 9.53589 11.1182 9.93301 11.1182 10.4229C11.1182 10.9128 11.5153 11.3099 12.0052 11.3099Z"
        fill={primary}
      />
      <path
        d="M14.7631 8.06095L12.2441 6.68795C12.1708 6.64804 12.0886 6.62713 12.0051 6.62713C11.9216 6.62713 11.8394 6.64804 11.7661 6.68795L9.9871 7.65795V1.85195C9.9879 1.77957 9.97297 1.70788 9.94335 1.64183C9.91373 1.57579 9.87012 1.51696 9.81554 1.46942C9.76096 1.42187 9.69671 1.38675 9.62723 1.36646C9.55774 1.34618 9.48468 1.34123 9.4131 1.35195L1.4001 2.55795C1.2819 2.57588 1.17404 2.63557 1.09607 2.7262C1.0181 2.81683 0.97518 2.93239 0.975098 3.05195V15.1519C0.975098 15.2846 1.02778 15.4117 1.12154 15.5055C1.21531 15.5993 1.34249 15.6519 1.4751 15.6519H14.5231C14.6557 15.6519 14.7829 15.5993 14.8767 15.5055C14.9704 15.4117 15.0231 15.2846 15.0231 15.1519V8.49995C15.0232 8.41017 14.9991 8.32203 14.9533 8.24479C14.9076 8.16754 14.8419 8.10404 14.7631 8.06095ZM1.9771 3.48195L8.9871 2.43195V14.6479H7.1901V11.3109C7.1901 11.1783 7.13742 11.0512 7.04365 10.9574C6.94988 10.8636 6.82271 10.8109 6.6901 10.8109H3.9001C3.76749 10.8109 3.64031 10.8636 3.54654 10.9574C3.45278 11.0512 3.4001 11.1783 3.4001 11.3109V14.6479H1.9771V3.48195ZM4.4001 14.6479V11.8109H6.1901V14.6479H4.4001ZM14.0221 14.6479H12.5051V12.9829C12.5051 12.8503 12.4524 12.7232 12.3587 12.6294C12.2649 12.5356 12.1377 12.4829 12.0051 12.4829C11.8725 12.4829 11.7453 12.5356 11.6515 12.6294C11.5578 12.7232 11.5051 12.8503 11.5051 12.9829V14.6479H9.9871V8.79995L12.0051 7.69995L14.0231 8.79995L14.0221 14.6479Z"
        fill={primary}
      />
    </>
  ),
  { viewBox: "0 0 16 17" }
);
